import React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <section className="hero is-fullheight">
    <div className="hero-body">
      <div className="container">
        <h1 className="title is-2">Page introuvable</h1>
        <Link className="return-link" to="/">
          <div className="columns is-vcentered is-mobile">
            <div className="column is-narrow">
              <span className="icon">
                <span class="icon is-large">
                  <i class="mdi mdi-arrow-left" />
                </span>
              </span>
            </div>
            <div className="column is-half">Revenir à la page principale</div>
          </div>
        </Link>
      </div>
    </div>
  </section>
)

export default NotFoundPage
